// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import { useState } from "react";
import styled from "styled-components/macro";
import Stepper from "../../Stepper";
// 
// ** UTILS **
import { Button } from "../../Style/StyledComponents";
import { formatDate } from "../../../contexts/Utils";
// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const AccordionDesktop = ({ user, offers, edit, offerEdit }) => {

  const [activeAccordion, setActiveAccordion] = useState(false)

  const has_offers = Object.keys(offers).find((e) => e === user.user_id);

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Sponsored key={user.user_id}>
    <S.FlexContainer>
      <S.UserInfos>
        <span>{user.firstname} {user.name}</span>
        <span>{user.email}</span>
        <span style={{ visibility: 'hidden' }}>Poullaillon Dornach</span>
      </S.UserInfos>
      <S.Flex>
        {edit && <S.Action onClick={() => edit(user)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" /></svg>
        </S.Action>}
        {has_offers && <S.Open onClick={() => setActiveAccordion(!activeAccordion)}>
          {activeAccordion ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" /></svg> : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" /></svg>}
        </S.Open>}
      </S.Flex>

    </S.FlexContainer>

    {/* OFFRES RELIEES AU FILEUL */}
    {activeAccordion && <S.Accordion
      active={activeAccordion}>
      {has_offers && <S.Labels>
        {Object.values(window._DATA.types.user_status).map((status, index) => {
          return <span key={index}>{status.label}</span>
        })}
      </S.Labels>}

      {Object.entries(offers).map(([key, offer]) => {
        return key === user.user_id && offer.map((value) => {
          const name = user.firstname + ' ' + user.name
          return <S.Offer key={value.title}>
            <S.Infos>
              {value.title}
              {value.time_create && <span>Postulé le : <S.Color>{formatDate({ time: value.time_create, display: 'date' })}</S.Color></span>}
              {value.time_update && <span>Embauché le : <S.Color>{formatDate({ time: value.time_update, display: 'date' })}</S.Color></span>}
              {value.reward_amount && <span>Montant de la prime : <S.Color>{value.reward_amount}€</S.Color></span>}
            </S.Infos>

            {(edit && +value.status === 2) && <S.ValidateButton onClick={() => offerEdit(value, name, user.sponsored_code)}>Valider l'embauche</S.ValidateButton>}
            <Stepper status={+value.status} />
          </S.Offer>
        })
      })}
    </S.Accordion>}

  </S.Sponsored>
};

export default AccordionDesktop;

const S = {}
S.Container = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
width: 100%;
box-sizing: border-box;
padding: 0 20px;

& h1 {
  font-size: 24px;
  font-weight: 800;
  color: ${({ theme }) => theme.textSecondary};
  margin: 0;
}
`

S.Labels = styled.div`
display: flex;
justify-content: flex-end;
color: ${({ theme }) => theme.grey};
font-size: 13px;
gap: 20px;
`

S.Sponsored = styled.div`
padding: 20px 0;
border-bottom: 1px solid #B4B4B4;
`

S.FlexContainer = styled.div`
display: flex;
justify-content: space-between;
`

S.Flex = styled.div`
display: flex;
align-items: center;
justify-content: center;
gap: 30px;
`

S.UserInfos = styled.div`
display: flex;
font-size: 15px;
font-weight: 500;
width: 65%;
color: ${({ theme }) => theme.textSecondary};
justify-content: space-between;
`

S.Action = styled.div`
display: flex;
align-items: center;
justify-content: center;

& svg {
  width: 20px;
  height: 20px;
  fill: ${({ theme }) => theme.primary};
}
`

S.Open = styled.div`
display: flex;
align-items: center;
justify-content: center;

& svg {
  width: 20px;
  height: 20px;
  fill: ${({ theme }) => theme.primary};
  transition: 0.3s ease-in-out;
}
`

S.Accordion = styled.div`
display: flex;
flex-direction: column;
margin-top: 10px;
`

S.Offer = styled.div`
display: flex;
justify-content: space-between;
padding: 10px 0;
font-size: 12px;
color: ${({ theme }) => theme.textSecondary};

&:not(:last-child) {
  border-bottom: 1px solid ${({ theme }) => theme.grey};
}
`
S.Infos = styled.div`
display: flex;
flex-direction: column;
font-size: 15px;
gap: 5px;
font-weight: 500;

& span {
  font-size: 13px;
  font-weight: 400
}
`

S.Color = styled.span`
color: ${({ theme }) => theme.textPrimary};
font-size: 10px;
font-weight: 500
`
S.Status = styled.div`
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
width: 21px;
height: 21px;
border-radius: 50%;
background: ${props => props.active ? '#F18903' : '#5B5B5B'};

& svg {
  fill: ${({ theme }) => theme.secondary};
  width: ${props => props.active ? '15' : '8'}px;
  height:${props => props.active ? '10' : '5'}px;
}
`

S.ValidateButton = styled(Button)`
font-size: 12px;
padding: 5px 10px;
`
// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import { useState, useEffect } from "react";
import styled from "styled-components/macro";
import Cookies from 'universal-cookie';
// 
// ** UTILS **
import Api from "../contexts/Api";
import { getValue, prepareFormData, Logout } from "../contexts/Utils";
import { useNotification } from "../components/UseNotification";
import Formfields from "../components/Formfields";
import { BackButton, Button, Container, Title } from "../components/Style/StyledComponents";
import { breakpoints } from "../components/Style/StyledBreakpoints";
import Share from "../components/Share";
// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Account = () => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [user, setUser] = useState({})
  const [errors, setErrors] = useState({})
  const [reset, setReset] = useState(false)

  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE);

  const { notification } = useNotification();

  // 
  // ─── GET USER ───────────────────────────────────────
  //
  useEffect(() => {
    const getUser = async () => {
      const data = await Api({
        endpoint: '/users',
        query: { single_user: userData.user_id },
        method: 'GET'
      });

      if (data.success) setUser(data.data)
    }
    if (!Object.keys(user).length) getUser()
  }, [user])

  // 
  // ─── HANDLE CHANGE INFOS ───────────────────────────────────────
  //
  const handleChangeInfos = async (e, id) => {
    e.preventDefault()

    let data = prepareFormData({ formId: id })

    if (data.errors) {
      setErrors({ ...errors, [id]: { ...data.errors } })
    } else {
      if (Object.keys(errors).length) {
        setErrors({});
      }

      if (data.fields.job_ids) {
        data.fields.old_jobs = user.job_ids
      }
      //
      // ─── API REQUEST ───────────────────────────────────────
      //
      const manageUser = await Api({
        data: { fields: data.fields, single_user: userData.user_id },
        method: 'PUT',
        endpoint: '/users',
      })

      // 
      // ─── ON SUCCESS ───────────────────────────────────────
      //
      if (manageUser.success) {
        // On update le state user avec les nouvelles informations
        if (data.fields.old_jobs) delete data.fields.old_jobs
        setUser({ ...user, ...data.fields })
        return notification({ variant: 'success', message: manageUser.message })
      } else {
        notification({ variant: 'error', message: manageUser.message })
      }
    }
  }

  // 
  // ─── HANDLE CHANGE PASSWORD ───────────────────────────────────────
  //
  const handleChangePassword = async (e, id) => {
    e.preventDefault()

    let data = prepareFormData({ formId: id })

    if (data.errors) {
      setErrors({ ...errors, [id]: { ...data.errors } })
    } else {
      if (Object.keys(errors).length) {
        setErrors({});
      }
      delete data.fields.confirmpassword
      //
      // ─── API REQUEST ───────────────────────────────────────
      //
      const manageUserPassword = await Api({
        data: { fields: data.fields, update_password: true, single_user: userData.user_id },
        method: 'PUT',
        endpoint: '/users',
      })

      // 
      // ─── ON SUCCESS ───────────────────────────────────────
      //
      if (manageUserPassword.success) {
        setReset(true)
        return notification({ variant: 'success', message: manageUserPassword.message })
      } else {
        notification({ variant: 'error', message: manageUserPassword.message })
      }
      setReset(false)
    }
  }

  // 
  // ─── ACCOUNT FORMS ───────────────────────────────────────
  //
  const account = [
    {
      title: 'Informations générales',
      id: 'account_infos',
      fields: [
        { type: "text", component: "text", name: "firstname", label: 'Prénom', width: '49%', required: true },
        { type: "text", component: "text", name: "name", label: 'Nom', width: '49%', required: true },
        { type: "email", component: "text", name: "email", label: 'Email', width: window.innerWidth > 800 ? '49%' : '100%', required: true },
        { type: "tel", component: "text", name: "phone", label: 'Téléphone', width: window.innerWidth > 800 ? '49%' : '100%', required: true },
        { type: "text", component: "text", name: "address", label: 'Adresse', width: window.innerWidth > 800 ? '49%' : '100%', required: true },
        { type: "text", component: "text", name: "cp", label: 'Code postal', width: '49%', required: true },
        { type: "text", component: "text", name: "city", label: 'Ville', width: '49%', required: true },
        { type: "select", component: "search_select_multiple_jobs", name: "job_ids", label: 'Métier(s) recherché(s)', width: window.innerWidth > 800 ? '49%' : '100%', required: true }
      ],
      handle: handleChangeInfos
    },
    {
      title: 'Sécurité',
      id: 'change_password',
      fields: [
        { type: "password", component: "text", name: "password_old", label: 'Ancien mot de passe', width: window.innerWidth > 800 ? '49%' : '100%', required: true, reset },
        { type: "password", component: "text", name: "password", label: 'Nouveau mot de passe', width: window.innerWidth > 800 ? '49%' : '100%', className: 'to_check', required: true, reset },
        { type: "password", component: "text", name: "confirmpassword", label: 'Confirmation du nouveau mot de passe', width: window.innerWidth > 800 ? '49%' : '100%', className: 'to_check', required: true, reset }
      ],
      handle: handleChangePassword
    },
    // {
    //   title: 'Coordonnées bancaires',
    //   id: 'bank_infos',
    //   fields: [
    //     { type: "text", component: "text", name: "iban", label: 'Iban', width: '100%', }
    //   ],
    //   handle: handleChangeInfos
    // },
  ]

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <Container>
    <S.FlexContainer>
      <Title>Mon compte</Title>
      {window.innerWidth < 600 && <BackButton onClick={Logout}>Déconnexion</BackButton>}
    </S.FlexContainer>
    {account.map((section, index) => {
      return <S.Form key={index} id={section.id}>
        {section.id === 'account_infos' && <S.Flex>
          <S.SponsorCode>Votre code parrain : <span>{user.sponsor_code}</span></S.SponsorCode>
          <Share url={'inscription'} sponsor={`?sponsor_code=${userData.sponsor_code}`} text={'Code Parrain'} icon={true} />
        </S.Flex>}
        <h2>{section.title}</h2>
        {section.fields.map(field => {
          return <Formfields key={field.name} field={{ ...field, ...{ default: user[field.name] }, ...{ error: getValue(errors, [section.id, field.name, 'message']) } }} />
        })}
        <Button as="input" type="submit" value="Enregistrer" className="button" onClick={(e) => section.handle(e, section.id)}></Button>
      </S.Form>
    })}
  </Container>
};

export default Account;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}

S.Form = styled.form`
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  & h2 {
    width: 100%;
  color: ${({ theme }) => theme.textPrimary};
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 30px;
  ${({ theme }) => theme.width > 800 && 'width: 100%'};
 }

  & input {
      margin: 0 auto;
      ${({ theme }) => theme.width > 800 && 'height: 40px'};
  }

  & .button {
    ${breakpoints("width", "", [
  { 800: "fit-content" },
], "min-width")};
    ${breakpoints("margin", "", [
  { 800: "0 0 0 auto" },
], "min-width")};
  }

  & .formfield_container {
    margin-bottom: 20px;

    & div {
      padding-right: 0;
    }
  }
`

S.FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  & button {
    width: fit-content;
  }
`

S.SponsorCode = styled.div`
color: ${({ theme }) => theme.textSecondary};
width: 100%;

& span {
  color: ${({ theme }) => theme.textPrimary};
  font-weight: 600
}
`

S.Flex = styled.div`
display: flex;
align-items: center;
margin-bottom: 30px;
gap: 20px;

& .share-button {
  width: 50px;
}
`
// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import styled from "styled-components/macro";
// ** UTILS **
import { ModalWrapper, ModalContent, ModalOverlay, Button, Close } from "../../Style/StyledComponents";
import Api from "../../../contexts/Api";
import { useNotification } from "../../UseNotification";
import Formfields from "../../Formfields";
import { useState } from "react";

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const EditOfferUser = (opt) => {

  // States
  const [rewardAmount, setRewardAmount] = useState({ value: '', error: '' });

  // INIT
  const existingOffer = opt.offer || {}

  const { notification } = useNotification();

  // 
  // ─── HANDLE CHANGE OFFER STATUS ───────────────────────────────────────
  //
  const handleChangeOfferStatus = async (e, offerStatus) => {
    e.preventDefault();

    // Check if reward amount is not empty
    if ((offerStatus === 3) && !rewardAmount.value.trim()) {
      setRewardAmount(prev => ({ ...prev, error: 'Champ obligatoire' }));
      return;
    }

    //
    // ─── API REQUEST ───────────────────────────────────────
    //
    const manageOffer = await Api({
      data: { fields: { status: offerStatus, ...(offerStatus === 3 ? { reward_amount: +rewardAmount.value } : {}) }, sponsor_code: opt.sponsor_code, single_user: existingOffer.user_id, offer_id: existingOffer.offer_id },
      method: 'PUT',
      endpoint: '/offers_applied',
    })

    // 
    // ─── ON SUCCESS ───────────────────────────────────────
    //
    if (manageOffer.success) {
      existingOffer.status = offerStatus
      opt.onSubmit({ status: offerStatus, user_id: existingOffer.user_id, offer_id: existingOffer.offer_id })
      return notification({ variant: 'success', message: manageOffer.message })
    } else {
      notification({ variant: 'error', message: manageOffer.message })
    }
  }

  // Set the reward amount
  const setStateReward = (value) => {
    setRewardAmount(prev => ({ ...prev, value, error: '' }));
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <ModalWrapper>
    <ModalOverlay onClick={opt.onClose} />
    <S.ModalContent>
      <Close onClick={opt.onClose}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M313 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L41 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L7 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L279 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L313 137z" /></svg>
      </Close>
      <h2>Confirmer l'embauche ?</h2>
      {opt.sponsor_code && <S.Fields>
        <Formfields key='reward_amount' field={{ type: "text", component: "text", name: "reward_amount", placeholder: 'Montant de la prime*', error: rewardAmount.error, handleChange: (value) => setStateReward(value) }} />
      </S.Fields>}
      <S.ButtonContainer>
        <Button as="input" type="submit" value="Refuser" onClick={(e) => handleChangeOfferStatus(e, 4)}></Button>
        <Button as="input" type="submit" value="Confirmer" onClick={(e) => handleChangeOfferStatus(e, 3)}></Button>
      </S.ButtonContainer>
    </S.ModalContent>
  </ModalWrapper>
};

export default EditOfferUser;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}

S.ModalContent = styled(ModalContent)`
overflow-y: auto;
max-width: 400px;

& h2 {
  text-align: center;
}
`

S.ButtonContainer = styled.div`
display: flex;
justify-content: space-around;
margin-top: 40px;

& input {
  width: fit-content;
}
`

S.Fields = styled.div`
margin-top: 20px;

& input {
  color: black;
}
`

// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
// 
// ** UTILS **
import Api from "../../contexts/Api";
import { formatDate, getValue, prepareFormData } from "../../contexts/Utils";
import { LinkAnimated, Button, Title, Container, EmptyContainer } from "../../components/Style/StyledComponents";
import Formfields from "../../components/Formfields";
import { Details } from "./List";
import Loading from "../../components/Loading";
import { useNotification } from "../../components/UseNotification";

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Detail = () => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [offer, setOffer] = useState({})
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(true)
  const [loadingApply, setLoadingApply] = useState(false)

  const params = useParams();
  const id = params.offer_id;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sponsorCode = searchParams.get('sponsor_code');

  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE);

  const navigate = useNavigate();
  const { notification } = useNotification();

  // 
  // ─── GET OFFER DETAILS ───────────────────────────────────────
  //
  useEffect(() => {
    const getOffer = async () => {
      const data = await Api({
        endpoint: '/offers',
        query: { offer_id: id },
        method: 'GET'
      });

      if (data.success) setOffer(data.data); setLoading(false)
    }
    if (!Object.keys(offer).length) getOffer()
  }, [offer, id])

  // 
  // ─── INSERT OFFER SHARED ───────────────────────────────────────
  //
  useEffect(() => {
    const insertOfferShared = async () => {
      const data = await Api({
        endpoint: '/offers_shared',
        data: { fields: { offer_id: id, user_id: userData.user_id }, sponsorCode: sponsorCode },
        method: 'POST'
      });
      if (!data.success) console.log(data.message)
    }
    if (sponsorCode) insertOfferShared()
  }, [sponsorCode, id])

  // 
  // ─── HANDLE USER APPLY ───────────────────────────────────────
  //
  const handleSubmit = async (e) => {
    e.preventDefault();

    let data = prepareFormData({ formId: 'applyForm' });

    if (data.errors) return setErrors({ 'applyForm': { ...data.errors } });
    else setErrors({});
    setLoadingApply(true)

    let formData = new FormData()

    if (data.fields.file) {
      formData.append('file', data.fields.file);
      delete data.fields.file;
    }

    formData.append('fields', JSON.stringify(data.fields))

    // Mettre sponsorCode hors des fields, car ce sont les fields qui sont repris ensuite dans le fichier php pour l'envoi à leur api
    if (sponsorCode) formData.append('sponsor_code', sponsorCode)

    formData.append('contract_type', offer.contract_type)
    formData.append('offer_id', id)
    formData.append('instance', offer.instance)
    formData.append('user_id', userData.user_id)
    const applyUser = await Api({
      data: formData,
      method: 'POST',
      endpoint: '/apply',
      removeHeader: true
    })

    if (applyUser.success) {
      notification({ variant: 'success', message: applyUser.message })
    } else {
      notification({ variant: 'error', message: applyUser.message })
    }
    setLoadingApply(false)
  }

  // 
  // ─── FORMFIELDS ───────────────────────────────────────
  //
  const fields = [
    { type: "text", component: "text", name: "firstname", label: 'Prénom', width: '49%', required: true },
    { type: "text", component: "text", name: "name", label: 'Nom', width: '49%', required: true },
    { type: "email", component: "text", name: "email", label: 'Email', width: window.innerWidth > 800 ? '49%' : '100%', required: true },
    { type: "tel", component: "text", name: "phone", label: 'Téléphone', width: window.innerWidth > 800 ? '49%' : '100%', required: true },
    { type: "text", component: "textarea", name: "comments", label: 'Commentaire', width: '100%' },
    { type: "text", component: "file", name: "file", label: 'Votre cv', width: ' 100%', required: true },
    { type: "text", component: "checkbox", name: "rgpd", label: 'J\'ai pris connaissance de la politique de confidentialité', width: '100%', required: true },
  ]

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <Loading loading={loading}>
    <S.Container>
      <S.Offer>
        <S.Back>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M234.8 36.25c3.438 3.141 5.156 7.438 5.156 11.75c0 3.891-1.406 7.781-4.25 10.86L53.77 256l181.1 197.1c6 6.5 5.625 16.64-.9062 22.61c-6.5 6-16.59 5.594-22.59-.8906l-192-208c-5.688-6.156-5.688-15.56 0-21.72l192-208C218.2 30.66 228.3 30.25 234.8 36.25z" /></svg>
          <LinkAnimated onClick={() => navigate(sponsorCode ? '/' : -1)}>
            Retour
          </LinkAnimated>
        </S.Back>

        {Object.keys(offer).length ? <div>
          <Title>
            {offer.title}
          </Title>
          <S.CardDetails>
            {(offer.time_start || offer.time_end) && <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M0 488c0 13.3 10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8V445c0-40.3-16-79-44.5-107.5L225.9 256l81.5-81.5C336 146 352 107.3 352 67V48h8c13.3 0 24-10.7 24-24s-10.7-24-24-24H24C10.7 0 0 10.7 0 24S10.7 48 24 48h8V67c0 40.3 16 79 44.5 107.5L158.1 256 76.5 337.5C48 366 32 404.7 32 445v19H24c-13.3 0-24 10.7-24 24zM273.5 371.5C293 391 304 417.4 304 445v19H80V445c0-27.6 11-54 30.5-73.5L192 289.9l81.5 81.5z" /></svg>
              {formatDate({ time: offer.time_start, display: 'date' })} {offer.time_end && ` - ${formatDate({ time: offer.time_end, display: 'date' })} `}
            </div>}
            {Boolean(offer.salary_min || offer.salary_max) && <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M112 112c0 35.3-28.7 64-64 64V336c35.3 0 64 28.7 64 64H464c0-35.3 28.7-64 64-64V176c-35.3 0-64-28.7-64-64H112zM0 64H48 528h48v48V400v48H528 48 0V400 112 64zm288 96a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" /></svg>
              {offer.salary_min} {offer.salary_max && `- ${offer.salary_max}`} {offer.salary_currency}
            </div>}
            {offer.contract_type && <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M48 448V64c0-8.8 7.2-16 16-16H224v80c0 17.7 14.3 32 32 32h80V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0H64zM96 96c-8.8 0-16 7.2-16 16s7.2 16 16 16h80c8.8 0 16-7.2 16-16s-7.2-16-16-16H96zm0 64c-8.8 0-16 7.2-16 16s7.2 16 16 16h80c8.8 0 16-7.2 16-16s-7.2-16-16-16H96zm54.2 221.8L160 349l16.7 55.6c1.9 6.3 7.4 10.8 13.9 11.3s12.8-2.9 15.7-8.8l10.6-21.3c.6-1.2 1.8-1.9 3.1-1.9s2.5 .7 3.1 1.9l10.6 21.3c2.7 5.4 8.2 8.8 14.3 8.8h40c8.8 0 16-7.2 16-16s-7.2-16-16-16H257.9l-6.2-12.4c-6-12-18.3-19.6-31.7-19.6c-8.6 0-16.8 3.1-23.1 8.6l-11.3-37.5C182.2 311.7 171.8 304 160 304s-22.2 7.7-25.6 19.1l-14.9 49.5c-2 6.8-8.3 11.4-15.3 11.4H96c-8.8 0-16 7.2-16 16s7.2 16 16 16h8.2c21.2 0 39.9-13.9 46-34.2z" /></svg>
              {offer.contract_type}
            </div>}
            {offer.company && <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M64 48c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16h80V400c0-26.5 21.5-48 48-48s48 21.5 48 48v64h80c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H64zM0 64C0 28.7 28.7 0 64 0H320c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm88 40c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H104c-8.8 0-16-7.2-16-16V104zM232 88h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H232c-8.8 0-16-7.2-16-16V104c0-8.8 7.2-16 16-16zM88 232c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H104c-8.8 0-16-7.2-16-16V232zm144-16h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H232c-8.8 0-16-7.2-16-16V232c0-8.8 7.2-16 16-16z" /></svg>
              {offer.company}
            </div>}
          </S.CardDetails>
          <S.Content>
            {offer.description}
          </S.Content>
          {offer.profile && <>
            <S.SubTitle>
              Profil recherché :
            </S.SubTitle>
            <S.Content>
              {offer.profile}
            </S.Content>
          </>}
          {offer.salary_additional && <>
            <S.SubTitle>
              Avantages :
            </S.SubTitle>
            <S.Content>
              {offer.salary_additional}
            </S.Content>
          </>}
          <Details color='rgba(255, 255, 255, 0.65)'>
            {offer.sector && <p>{offer.sector}</p>}
            {(offer.city || offer.postal_code) && <>
              <span>&#8226;</span>
              <p>{offer.city}{offer.postal_code && `, ${offer.postal_code}`}</p>
            </>}
          </Details>
        </div> : <EmptyContainer>
          <h1>
            Oops ...
          </h1>
          L'offre n'est plus disponible.
        </EmptyContainer>}
      </S.Offer>
      {Object.keys(offer).length && <S.Form id='applyForm'>
        <h2>Je postule</h2>
        {fields.map(field => {
          return <Formfields key={field.name} field={{ ...field, ...{ default: userData[field.name] }, ...{ error: getValue(errors, ['applyForm', field.name, 'message']) } }} />
        })}
        <Button as="input" type="submit" value={loadingApply ? "Envoi en cours..." : "Envoyer"} onClick={handleSubmit}></Button>
      </S.Form>}
    </S.Container>
  </Loading>

};

export default Detail;

const S = {}

S.Container = styled(Container)`
flex-direction: ${({ theme }) => theme.width > 800 ? 'row' : 'column'};
`
S.Offer = styled.div`
display: flex;
flex-direction: column;
${({ theme }) => theme.width > 800 && 'width: 60%'};
${({ theme }) => theme.width > 800 && 'padding-right: 20px'};
`
S.Back = styled.div`
display: flex;
align-items: center;
gap: 5px;
margin-bottom: 15px;

& svg {
  width: 13px;
  height: 15px;
  fill: ${({ theme }) => theme.secondary};
}

& a {
  font-size: 14px;
  margin: 0;
  padding-bottom: 0;
}
`

S.CardDetails = styled.div`
display: flex;
flex-wrap: wrap;
color: ${({ theme }) => theme.opacityFontDarker};
justify-content: ${({ theme }) => theme.width > 800 ? 'flex-start' : 'space-between'};
gap: ${({ theme }) => theme.width > 800 ? '20' : '10'}px;
margin: 20px 0;

& div {
  display: flex;
  align-items: center;
  font-size: 15px;
  gap: 5px;

  & svg {
    fill: ${({ theme }) => theme.primary};
    width: 15px;
    height: 15px;
  }

  & span {
    color: ${({ theme }) => theme.textPrimary};
  }
}

`

S.Content = styled.div`
color: ${({ theme }) => theme.opacityFontDarker};
font-size: 14px;
font-weight: 500;
margin-bottom: 20px;
`

S.SubTitle = styled.h2`
color: ${({ theme }) => theme.textPrimary};
font-size: 20px;
font-weight: 500;
`

S.Form = styled.form`
  width: ${({ theme }) => theme.width > 800 ? '40%' : '100%'};
  margin: 20px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;

  & h2 {
    width: 100%;
    color: ${({ theme }) => theme.textPrimary};
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 30px;
    ${({ theme }) => theme.width > 800 && 'width: 100%'};
 }

 & input {
    margin: 0 auto;
    ${({ theme }) => theme.width > 800 && 'height: 40px'};
 }

  & .formfield_container {
    margin-bottom: 20px;

    & div {
      padding-right: 0;

      &:after {
        border-top: 1px solid black;
        border-right: 1px solid black;
        margin-right: 10px;
      }

      & select {
      background: ${({ theme }) => theme.secondary};
      border-radius: 5px;
      color: black;
      }
    }
  }
`
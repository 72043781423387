// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { Route, Routes } from 'react-router-dom';
import AuthChecker from './AuthChecker';
import AppData from './UseData';
import Layout from '../Layout';
import ViewContainer from '../ViewContainer';

import Login from '../../views/Login';
import Register from '../../views/Register';
import ResetPassword from '../../views/ResetPassword';
import ForgotPassword from '../../views/ForgotPassword';

import Home from '../../views/Home';
import HomeLogedOut from '../../views/HomeLogedOut';
import NotFound from '../../views/NotFound';
import Account from '../../views/Account';

import Detail from '../../views/Offers/Detail';
import Sponsors from '../../views/Sponsors';
import Rewards from '../../views/Rewards';
import Applications from '../../views/Applications';

import Admin from '../../views/Admin/Admin';
import EditOffer from '../../views/Admin/EditOffer';
import Cookies from 'universal-cookie';
import LayoutAuth from '../LayoutAuth';

import Faq from '../../views/Faq';
import LegalNotice from '../../views/LegalNotice';
import Confidentiality from '../../views/Confidentiality';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Router = () => {

  // Cookies
  const cookies = new Cookies();
  // COOKIES TAKEN HERE ARE FROM THE LOGIN, NOT FROM AUTHCHECKER 
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE) || {};

  const routes = [
    { path: '/', component: <Home />, title: 'Accueil - SatisForYou' },
    { path: '/offer-detail/:offer_id', component: <Detail />, title: 'Détail de l\'offre' },
    { path: '/postulations', component: <Applications />, title: 'Mes postulations' },
    { path: '/parrainage', component: <Sponsors />, title: 'Mes parrainages' },
    { path: '/recompenses', component: <Rewards />, title: 'Mes niveaux' },
    { path: '/mon-compte', component: <Account />, title: 'Mon compte' },
    { path: '/admin', component: <Admin />, title: 'Admin Panel', role: [1] },
    { path: '/admin/offer/:offer_id', component: <EditOffer />, title: 'Modifier l\'offre', role: [1] },
    { path: '/faq', component: <Faq />, title: 'Foire Aux Questions' },
    { path: '/mentions-legales', component: <LegalNotice />, title: 'Mentions légales' },
    { path: '/politique-confidentialite', component: <Confidentiality />, title: 'Politique de confidentialité' }
  ]

  const authRoutes = [
    { path: '/', component: <HomeLogedOut />, title: 'Accueil - SatisForYou', cond: !Object.keys(userData).length },
    { path: '/connexion', component: <Login />, title: 'Connexion' },
    { path: '/inscription', component: <Register />, title: 'Inscription' },
    { path: '/mot-de-passe-oublie', component: <ForgotPassword />, title: 'Mot de passe oublié' },
    { path: '/reinitialiser-le-mot-de-passe/:token', component: <ResetPassword />, title: 'Réinitialiser le mot de passe' },
  ]

  // 
  // ─── DECLARATION OF ALL NECESSARY ROUTES ───────────────────────────────────────
  //
  return <Routes>
    <Route element={<LayoutAuth />}>
      {authRoutes.map((route) => {
        if (!route.hasOwnProperty('cond') || (route.cond)) {
          return <Route key={route.path} path={route.path} element={<>
            <ViewContainer title={route.title}>{route.component}</ViewContainer>
          </>} />
        }
      })}
    </Route>

    <Route element={<AuthChecker />}>
      <Route element={<AppData />}>
        <Route path="/" element={<Layout />}>
          {routes.map((route) => {
            if (!route.hasOwnProperty('role') || (route.role && route.role.includes(parseInt(userData.role)))) {
              return <Route key={route.path} path={route.path} element={<>
                <ViewContainer title={route.title}>{route.component}</ViewContainer>
              </>} />
            }
            return null;
          })}
        </Route>
      </Route>
    </Route>
    <Route path="*" element={<NotFound />} />
  </Routes >

};

export default Router
// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import { useState } from "react";
import styled from "styled-components/macro";
// ** UTILS **
import { ModalWrapper, ModalContent, ModalOverlay, Form, Button, Close } from "../../Style/StyledComponents";
import { getValue, prepareFormData } from "../../../contexts/Utils";
import Formfields from "../../Formfields";
import Api from "../../../contexts/Api";

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ExportModal = (opt) => {
  const [showDates, setShowDates] = useState(false);
  const [errors, setErrors] = useState({});

  const downloadExcel = async (e) => {
    e.preventDefault();
    let apiQuery = {};

    const data = prepareFormData({ formId: 'export-postulations' });
    if (data.errors) return setErrors({ 'export-postulations': { ...errors } });
    else setErrors({});

    // get missions by date
    if (!data.fields.all) {
      apiQuery = {
        start_date: data.fields.start_date,
        end_date: data.fields.end_date
      }
    }

    const query = await Api({
      endpoint: '/export',
      method: 'GET',
      query: apiQuery
    })

    const filename = data.fields.all ? 'export.xlsx' : `export_${data.fields.start_date}_${data.fields.end_date}.xlsx`;

    const link = document.createElement('a');
    link.href = "data:image/png;base64," + query.data;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();

    opt.onClose();
  }

  const formFields = [
    { name: 'all', type: "text", component: "checkbox", label: 'Toutes les candidatures', checked: true, handleChange: (value) => setShowDates(!showDates) },
    { name: 'start_date', type: 'date', label: 'Date de début', default: '', cond: showDates, required: true },
    { name: 'end_date', type: 'date', label: 'Date de fin', default: '', cond: showDates, required: true },
  ]

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <ModalWrapper>
    <ModalOverlay onClick={opt.onClose} />
    <S.ModalContent>
      <Close onClick={opt.onClose}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M313 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L41 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L7 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L279 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L313 137z" /></svg>
      </Close>
      <S.Form id="export-postulations">
        <h2>Souhaitez vous exporter les candidatures en cours ?</h2>
        {formFields.map(field => {
          const checkCond = field.cond || !field.hasOwnProperty('cond')
          return checkCond && <Formfields key={field.name} field={{ ...field, ...{ error: getValue(errors, ['export-postulations', field.name, 'message']) } }} />
        })}
        <Button as="input" type="submit" value="Exporter" onClick={downloadExcel}></Button>
      </S.Form>
    </S.ModalContent>
  </ModalWrapper>
};

export default ExportModal;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}

S.ModalContent = styled(ModalContent)`
overflow-y: auto;
`

S.Form = styled(Form)`
align-items: flex-start;

  & .formfield_container {
    & label {
      color: ${({ theme }) => theme.third};
    }

    & input, & select {
      color: ${({ theme }) => theme.third};
      border: 1px solid ${({ theme }) => theme.opacityFontLight};

      &:focus, :not(&[value=""]) {
        border: 1px solid ${({ theme }) => theme.primary};
        outline: none;
      }
    }
  }

  & .dropdown-jobs {
    & .job-label {
      color: ${({ theme }) => theme.third};
    }
  }
  & .selectedItem {
    color: ${({ theme }) => theme.third};
  }
`
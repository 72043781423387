// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import Cookies from 'universal-cookie';
// 
// ** UTILS **
import { prepareFormData, getValue } from '../contexts/Utils';
import Api from '../contexts/Api';
// 
// ** COMPONENTS **
import { BackButton, Button, ButtonLink } from '../components/Style/StyledComponents';
import Formfields from '../components/Formfields';
import GeoSearch from '../components/GeoSearch';
// 
// ** PNG **
import candidat from '../assets/static/candidat.png'
import entreprise from '../assets/static/entreprise.png'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Register = () => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [types, setTypes] = useState({});
  const [jobs, setJobs] = useState({});
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({})
  const [sponsor, setSponsor] = useState(1);
  const [activeStep, setActiveStep] = useState(1);
  const [registration, setRegistration] = useState({});

  // TRACKING SPONSOR CODE + URL OFFRE PARTAGEE
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sponsorCode = searchParams.get('sponsor_code');
  const url = searchParams.get('url');

  // Cookies
  const cookies = new Cookies();

  // redirect the user
  let navigate = useNavigate();

  // 
  // ─── GET TYPES ───────────────────────────────────────
  //
  useEffect(() => {
    const getTypes = async () => {
      const data = await Api({ endpoint: '/data', method: 'GET' });
      if (data.success) setTypes(data.data)
    }
    if (!Object.keys(types).length) getTypes()
  }, [types])

  // 
  // ─── GET JOBS ───────────────────────────────────────
  //
  useEffect(() => {
    const getJobs = async () => {
      const data = await Api({ endpoint: '/jobs', method: 'GET' });
      if (data.success) setJobs(data.data)
    }
    if (!Object.keys(jobs).length) getJobs()
  }, [jobs])

  // 
  // ─── SET SPONSOR CODE ───────────────────────────────────────
  //
  useEffect(() => {
    if (sponsorCode && sponsorCode.length) {
      setForm({ ...form, sponsored_code: sponsorCode, type: 2 })
      setSponsor(2)
    }
  }, [sponsorCode])

  // 
  // –––– HANDLE NEXT ––––––––––––––––
  //
  const handleNext = async (e) => {
    e.preventDefault();

    const formId = 'registrationForm';
    const formData = prepareFormData({ formId });
    const fields = formData.fields;

    if (formData.errors) {
      setErrors((prevErrors) => ({ ...prevErrors, [formId]: { ...formData.errors } }));
      return;
    }

    if (activeStep === 2 && fields.sponsored_code) {
      const { sponsored_code } = fields;

      const check = await Api({
        method: 'GET',
        query: { sponsored_code },
        endpoint: '/auth/register/check',
      });

      if (!check.success) {
        setErrors((prevErrors) => ({ ...prevErrors, [formId]: { ...check.fields } }));
        return;
      }
    }

    if (activeStep === 4) {
      const { email, phone } = fields;

      const check = await Api({
        method: 'GET',
        query: { email, phone },
        endpoint: '/auth/register/check',
      });

      if (!check.success) {
        setErrors((prevErrors) => ({ ...prevErrors, [formId]: { ...check.fields } }));
        return;
      }
    }

    setErrors({});
    setForm((prevForm) => ({ ...prevForm, ...fields }));

    if (activeStep === 5) {
      setForm((prevForm) => ({ ...prevForm, ...fields }));
      handleFinish({ ...form, ...fields });
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  // 
  // –––– HANDLE FINISH ––––––––––––––––
  //
  const handleFinish = async (form) => {
    // On supprime le champ confirmpassword et type car inutiles dans la db
    delete form.confirmpassword
    delete form.type

    // On convertit le champ job en json pour la db
    form.job = JSON.stringify(form.job)

    //
    // ─── API REQUEST ───────────────────────────────────────
    //
    const addUser = await Api({
      data: url ? { fields: form, offer_id: url.split("/")[2] } : { fields: form },
      method: 'POST',
      endpoint: '/auth/register',
    })

    // 
    // ─── ON SUCCESS ───────────────────────────────────────
    //
    if (addUser.success && addUser.access_token) {
      setRegistration({ success: true, message: addUser.message })
      cookies.set(process.env.REACT_APP_USER_COOKIE, addUser.data, {
        expires: new Date(Date.now() + 604800000), // time until expiration
        secure: false, // true if using https
      })
    } else {
      setRegistration({ success: false, message: addUser.message })
    }
    setActiveStep(activeStep + 1);
  }

  // J'attribue mes images comme ceci avant de les rendre de façon dynamiques une fois stockées sur le serveur
  if (Object.keys(types).length) Object.values(types.user_type).map((value) => {
    if (value.id === 1) value.img = candidat
    else value.img = entreprise
  })

  // 
  // ─── ARRAY STEPS REGISTRATION ───────────────────────────────────────
  //
  const stepper = [
    {
      title: <><h1>Bienvenue sur</h1><h2>Satis For You !</h2></>,
      p: <p>Créons votre compte ensemble.</p>,
      button_text: "C'est parti !",
      button_function: () => setActiveStep(activeStep + 1),
    },
    {
      title: <h3>Vous êtes ici en tant que...</h3>,
      fields: [
        { type: "radio", component: "radio", name: "type", value: Object.keys(types).length && Object.values(types.user_type), handleChange: (e) => setSponsor(e), required: true },
        { type: "text", component: "text", name: "sponsored_code", placeholder: 'Code parrain*', cond: sponsor === 2, required: true }
      ],
      button_text: "Suivant",
      button_function: handleNext,
    },
    {
      title: <h3>Je suis Bond.<br />James Bond.<br /> Et vous ? </h3>,
      fields: [
        { type: "text", component: "text", name: "firstname", placeholder: 'Prénom*', icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>`, width: window.innerWidth > 600 ? '48%' : '100%', required: true },
        { type: "text", component: "text", name: "name", placeholder: 'Nom*', icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>`, width: window.innerWidth > 600 ? '48%' : '100%', required: true }
      ],
      button_text: "Suivant",
      button_function: handleNext,
    },
    {
      title: <h3>Veuillez entrer vos informations. Promis, on ne les revend pas.</h3>,
      fields: [
        { type: "tel", component: "text", name: "phone", placeholder: 'Tél.*', icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>`, width: window.innerWidth > 600 ? '48%' : '100%' },
        { type: "email", component: "text", name: "email", placeholder: 'Email*', icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>`, width: window.innerWidth > 600 ? '48%' : '100%' },
        { type: "password", component: "text", name: "password", placeholder: 'Mot de passe*', icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M336 352c97.2 0 176-78.8 176-176S433.2 0 336 0S160 78.8 160 176c0 18.7 2.9 36.8 8.3 53.7L7 391c-4.5 4.5-7 10.6-7 17v80c0 13.3 10.7 24 24 24h80c13.3 0 24-10.7 24-24V448h40c13.3 0 24-10.7 24-24V384h40c6.4 0 12.5-2.5 17-7l33.3-33.3c16.9 5.4 35 8.3 53.7 8.3zM376 96a40 40 0 1 1 0 80 40 40 0 1 1 0-80z"/></svg>`, width: window.innerWidth > 600 ? '48%' : '100%', className: 'to_check', required: true },
        { type: "password", component: "text", name: "confirmpassword", icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M336 352c97.2 0 176-78.8 176-176S433.2 0 336 0S160 78.8 160 176c0 18.7 2.9 36.8 8.3 53.7L7 391c-4.5 4.5-7 10.6-7 17v80c0 13.3 10.7 24 24 24h80c13.3 0 24-10.7 24-24V448h40c13.3 0 24-10.7 24-24V384h40c6.4 0 12.5-2.5 17-7l33.3-33.3c16.9 5.4 35 8.3 53.7 8.3zM376 96a40 40 0 1 1 0 80 40 40 0 1 1 0-80z"/></svg>`, placeholder: 'Confirmer mot de passe*', width: window.innerWidth > 600 ? '48%' : '100%', className: 'to_check', required: true }
      ],
      button_text: "Suivant",
      button_function: handleNext,
    },
    // {
    //   title: <h3>Pour recevoir vos récompenses, veuillez renseigner vos coordonnées bancaires.</h3>,
    //   fields: [
    //     { type: "text", component: "text", name: "iban", placeholder: 'Iban', icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>`, width: '100%' },
    //   ],
    //   button_text: "Suivant",
    //   button_function: handleNext,
    // },
    {
      title: <h3>Quel est le métier que <br /> vous recherchez ?</h3>,
      fields: [
        { type: "select", component: "search_select_multiple_jobs", name: "job_ids", placeholder: 'Métier(s) recherché(s)*', icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M176 56V96H336V56c0-4.4-3.6-8-8-8H184c-4.4 0-8 3.6-8 8zM128 96V56c0-30.9 25.1-56 56-56H328c30.9 0 56 25.1 56 56V96v32V480H128V128 96zM64 96H96V480H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64zM448 480H416V96h32c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64z"/></svg>`, data: Object.keys(jobs).length && Object.values(jobs), width: '100%', required: true }
      ],
      geosearch: <GeoSearch placeholder="Ville*" icon='<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>' required={true} name='city' error={getValue(errors, ['registrationForm', 'city', 'message'])} />,
      button_text: "Suivant",
      button_function: handleNext,
    },
    {
      title: <h2>{registration.success ? 'Merci pour votre inscription.' : 'Oops ...'}</h2>,
      p: <p>{registration.message}</p>,
      button_text: "Accueil",
      url: url ? `${url}?sponsor_code=${sponsorCode}` : '/',
    },
  ]

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    {/* Formulaire d'inscription */}
    <S.Form id='registrationForm' name='registrationForm'>
      {stepper.map((step, index) => {
        const number = index + 1
        return activeStep === number && <div key={number}>
          <S.TitleContainer key={step.index}>
            {step.title}
          </S.TitleContainer>
          {step.p && step.p}
          {step.fields && <S.FormFieldsContainer margin={sponsor === 1 && activeStep === 2}>
            {step.fields.map((field) => {
              const checkCond = field.cond || !field.hasOwnProperty('cond')
              return checkCond && <Formfields key={field.name} field={{ ...field, ...{ default: field.name === 'type' ? +form[field.name] : form[field.name] }, ...{ error: getValue(errors, ['registrationForm', field.name, 'message']) } }} />
            })}
            {step.geosearch && step.geosearch}
          </S.FormFieldsContainer>}
          <S.ButtonContainer margin={activeStep !== 2} width={activeStep === 1}>
            {step.url ? <ButtonLink href={step.url}>{step.button_text}</ButtonLink> : <Button onClick={step.button_function}>{step.button_text}</Button>}
            <BackButton onClick={() => activeStep === 1 ? navigate('/connexion') : setActiveStep(activeStep - 1)}>
              {activeStep === 1 ? 'Retour' : 'Précédent'}</BackButton>
          </S.ButtonContainer>
        </div>
      })}
    </S.Form>
    {/* Stepper */}
    <S.StepperContainer>
      {stepper.map((step, index) => {
        const number = index + 1
        return [activeStep, activeStep - 1, activeStep + 1].includes(number) && <S.Stepper key={number} className={activeStep === number ? 'active' : ''}> {number} </S.Stepper>
      })}
    </S.StepperContainer>
  </S.Container>
};

export default Register;

// 
// ─── STYLE DEFINIITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 
  setSponsor(2);
  z-index: 1;

  & p {
    color: ${({ theme }) => theme.secondary};
    font-size: 14px;
    font-weight: bold;
    margin: 20px auto 40px auto;
    text-align: center;
  }
`

S.Form = styled.form`
  max-width: 500px;
  margin: auto;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

 & > div {
  display: flex;
  flex-direction: column;
  gap: 
  setSponsor(2);
 }
`

S.TitleContainer = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  font-weight: bold;
  color: ${({ theme }) => theme.secondary};
  text-align: center;
  position: static;

  & > h1, & > h3 {
    margin: 0 auto;
    font-size: 24px;
  }

  & > h2 {
    margin: 0 auto;
    font-size: 40px;
  }
`

S.FormFieldsContainer = styled.div`
width: 100%;
display: flex;
flex-wrap: wrap;
gap: 15px;
align-items: center;
justify-content: ${({ theme }) => theme.width > 800 ? 'center' : 'space-between'};
margin-bottom: 30px;


& .formfield_container {
  ${props => props.margin && 'margin-bottom: 50px'};

  & input[name="sponsored_code"] {
    margin: 0 auto;
    width: 70%;
  }

  & svg {
  fill: ${({ theme }) => theme.secondary}
  }


  & input {
    color: ${({ theme }) => theme.secondary};

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
      -webkit-box-shadow: 0 0 0px 1000px #000 inset;
      -webkit-text-fill-color:${({ theme }) => theme.secondary};
    }
  }

  & select {
    background: rgba(255, 255, 255, 0.14);
    border-radius: 2px
  }
}
`

S.ButtonContainer = styled.div`
display: flex;
flex-direction: row-reverse;
justify-content: center;
align-items: center;
gap: 15px;
width: 100%;
position: relative;
max-width: 3
setSponsor(2);
margin: ${props => props.margin ? "30" : "0"}px auto 0 auto;

& button {
  width: ${props => props.width ? "fit-content" : "50%"};
}
`

S.StepperContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
`

S.Stepper = styled.div`
display: flex;
justify-content: center;
align-items: center;
background: ${({ theme }) => theme.secondary};
width: 35px;
height: 35px;
border-radius: 50%;
color: ${({ theme }) => theme.primary};
font-size: 15px;
font-weight: bold;
line-height: 1;
-webkit-user-select: none;
user-select: none;

  &.active {
  margin-left: -7px;
  margin-right: -7px;
  z-index: 1;
  font-size: 25px;
  width: 59px;
  height: 59px;
  color: ${({ theme }) => theme.secondary};
  background: ${({ theme }) => theme.primary};
}
`
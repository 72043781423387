// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoints } from './Style/StyledBreakpoints';
import Cookies from 'universal-cookie';
import { Logout } from '../contexts/Utils';
// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Navigation = () => {
  const phone = window.innerWidth < 800
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE) || {};
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.NavigationContainer>
    <NavLink to='/' exact="true">
      {phone && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" /></svg>}
      Accueil
    </NavLink>
    <NavLink to='/postulations'>
      {phone && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M176 56V96H336V56c0-4.4-3.6-8-8-8H184c-4.4 0-8 3.6-8 8zM128 96V56c0-30.9 25.1-56 56-56H328c30.9 0 56 25.1 56 56V96v32V480H128V128 96zM64 96H96V480H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64zM448 480H416V96h32c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64z" /></svg>}
      Postulations
    </NavLink>
    <NavLink to='/parrainage'>
      {phone && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M323.4 85.2l-96.8 78.4c-16.1 13-19.2 36.4-7 53.1c12.9 17.8 38 21.3 55.3 7.8l99.3-77.2c7-5.4 17-4.2 22.5 2.8s4.2 17-2.8 22.5l-20.9 16.2L550.2 352H592c26.5 0 48-21.5 48-48V176c0-26.5-21.5-48-48-48H516h-4-.7l-3.9-2.5L434.8 79c-15.3-9.8-33.2-15-51.4-15c-21.8 0-43 7.5-60 21.2zm22.8 124.4l-51.7 40.2C263 274.4 217.3 268 193.7 235.6c-22.2-30.5-16.6-73.1 12.7-96.8l83.2-67.3c-11.6-4.9-24.1-7.4-36.8-7.4C234 64 215.7 69.6 200 80l-72 48H48c-26.5 0-48 21.5-48 48V304c0 26.5 21.5 48 48 48H156.2l91.4 83.4c19.6 17.9 49.9 16.5 67.8-3.1c5.5-6.1 9.2-13.2 11.1-20.6l17 15.6c19.5 17.9 49.9 16.6 67.8-2.9c4.5-4.9 7.8-10.6 9.9-16.5c19.4 13 45.8 10.3 62.1-7.5c17.9-19.5 16.6-49.9-2.9-67.8l-134.2-123z" /></svg>}
      Parrainage
    </NavLink>
    <NavLink to='/recompenses'>
      {phone && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z" /></svg>}
      Mes niveaux
    </NavLink>
    <NavLink to='/mon-compte'>
      {phone && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" /></svg>}
      Mon compte
    </NavLink>
    {userData.role === 1 && <NavLink to='/admin'>
      {phone && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" /></svg>}
      Dashboard
    </NavLink>}
    {window.innerWidth > 600 && <div className='logout' onClick={Logout}>Déconnexion</div>}
  </S.NavigationContainer>
};

export default Navigation;

// 
// ─── STYLE DEFINIITION ───────────────────────────────────────
//
const S = {}
S.NavigationContainer = styled.nav`
  background: ${({ theme }) => theme.width > 800 ? 'transparent' : theme.primary};
  align-items: center;
  justify-content: space-between;
  ${breakpoints("justify-content", "", [
  { 900: "flex-end!important" },
  { 800: "center" },
], "min-width")};
  box-sizing: border-box;
  display: flex;
  width: 100%;  
  margin-right: auto;
  ${breakpoints("width", "", [
  { 1000: "82%!important" },
  { 900: "75%!important" },
], "min-width")};
  position: ${({ theme }) => theme.width > 800 ? 'inherit' : 'fixed'};
  bottom: 0px;
  ${breakpoints("padding", "", [
  { 800: "0 10px" },
])};
  height: 70px;
  z-index: 100;
  gap: ${({ theme }) => theme.width > 800 ? '40' : '0'}px;
  
  & a, & .logout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: ${({ theme }) => theme.width > 800 ? '15' : '10'}px;
    font-weight: ${({ theme }) => theme.width > 800 ? '700' : '500'};
    position: relative;
    gap: 2px;
    color: ${({ theme }) => theme.textSecondary};
    cursor: pointer;
  }

  & a.active:after, & .logout:hover:after {
    content: "";
    position: absolute;
    ${({ theme }) => theme.width > 800 ? 'bottom: -6px' : 'top: -18px'};
    width: ${({ theme }) => theme.width > 800 ? '100%' : '20px'};
    height: 2px;
    border-radius: 0 0 10px 10px;
    background: ${({ theme }) => theme.width > 800 ? theme.primary : theme.secondary};
  }

  & svg {
    fill: ${({ theme }) => theme.secondary};
    width: 20px;
    height: 20px;
    overflow: visible;
  }
`
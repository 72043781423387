// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import styled, { keyframes } from "styled-components/macro";

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const TokenBar = (opt) => {
  let { token, reward, hide } = opt

  // SVG that we are gonna use several times
  const coins = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M190.5 68.8L225.3 128l-1.3 0-72 0c-22.1 0-40-17.9-40-40s17.9-40 40-40l2.2 0c14.9 0 28.8 7.9 36.3 20.8zM64 88c0 14.4 3.5 28 9.6 40L32 128c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32l448 0c17.7 0 32-14.3 32-32l0-64c0-17.7-14.3-32-32-32l-41.6 0c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88l-2.2 0c-31.9 0-61.5 16.9-77.7 44.4L256 85.5l-24.1-41C215.7 16.9 186.1 0 154.2 0L152 0C103.4 0 64 39.4 64 88zm336 0c0 22.1-17.9 40-40 40l-72 0-1.3 0 34.8-59.2C329.1 55.9 342.9 48 357.8 48l2.2 0c22.1 0 40 17.9 40 40zM32 288l0 176c0 26.5 21.5 48 48 48l144 0 0-224L32 288zM288 512l144 0c26.5 0 48-21.5 48-48l0-176-192 0 0 224z" /></svg>

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    {!hide && <S.Tokens>
      {token}
      {/* COINS */}
      {coins}
    </S.Tokens>}
    <S.LevelContainer>
      <S.InfosContainer>
        <S.Infos>
          {reward.end ? <div>Dernier niveau atteint</div>
            : <div>
              Encore {reward.points - token}
              {coins}
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M297 239c9.4 9.4 9.4 24.6 0 33.9L105 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L71 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L297 239z" /></svg>
              </span>
              Niveau {reward.label}
            </div>}
          <div>{reward.points} {coins}</div>
        </S.Infos>
        <S.ProgressBar>
          <S.Progress width={reward.end ? '100%' : (token / reward.points) * 100}></S.Progress>
        </S.ProgressBar>
      </S.InfosContainer>
      <S.Level>
        Niv
        <span>{reward.label}</span>
      </S.Level>
    </S.LevelContainer>
  </S.Container>
};

export default TokenBar;

const fill = keyframes`
  from {
    width: 0%;
  }
  to {
    width: ${(props) => props.width}%;
  }
`;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}

S.Container = styled.div`
width: 100%;
display: flex;
flex-direction: column;
gap: 30px;
`

S.Tokens = styled.div`
display: flex;
align-items: center;
gap: 10px;
color: ${({ theme }) => theme.textSecondary};
font-weight: 700;
font-size: 23px;

& svg {
  width: 23px;
  height: 23px;
  fill: ${({ theme }) => theme.primary};
}
`

S.LevelContainer = styled.div`
display: flex;
justify-content: ${({ theme }) => theme.width > 800 ? 'flex-start' : 'space-between'};
color: ${({ theme }) => theme.textSecondary};
${({ theme }) => theme.width > 800 && 'gap: 10px'}
`

S.Level = styled.div`
display: flex;
width: 20%;
flex-direction: column;
align-items: center;
justify-content: center;
border-radius: 50%;
width: 44px;
height: 44px;
color: ${({ theme }) => theme.textSecondary};
background: ${({ theme }) => theme.primary};
font-size: 12px;

& span {
  font-size: 20px;
  font-weight: 600;
}
`

S.InfosContainer = styled.div`
display: flex;
flex-direction: column;
width: ${({ theme }) => theme.width > 800 ? '40%' : '80%'};
gap: 10px;
`

S.Infos = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
font-size: 12px;

& svg {
  width: 10px;
  height: 10px;
  fill: ${({ theme }) => theme.primary};
  margin: 0 3px;
}

& span > svg {
  fill: ${({ theme }) => theme.secondary};
}
`

S.ProgressBar = styled.div`
width: 100%;
height: 13px;
border-radius: 10px;
background: ${({ theme }) => theme.secondary};
`

S.Progress = styled.div`
height: 100%;
border-radius: ${props => props.width === '100%' ? '10px' : '10px 0px 0px 10px'};
background-color:${({ theme }) => theme.primary};
width: ${props => props.width}%;
animation: ${fill} 1s ease-out forwards;
`
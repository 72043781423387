// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import { useState, useEffect } from "react";
import styled from "styled-components/macro";
// 
// ** UTILS **
import Api from "../../../contexts/Api";
import { useNotification } from "../../UseNotification";
import AlertModal from "../../Modals/Admin/AlertModal";
import EditReward from "./EditReward";
//
// ** COMPONENTS **
import { Reward, AdminComponent, Button } from "../../Style/StyledComponents";
import Loading from "../../Loading";
import Formfields from "../../Formfields";
import { breakpoints } from "../../Style/StyledBreakpoints";
// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const RewardsList = () => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [rewards, setRewards] = useState([])
  const [displayForm, setDisplayForm] = useState({})
  const [show, setShow] = useState(false)
  const [openAlert, setOpenAlert] = useState({ open: false, reward_id: null })
  const [loading, setLoading] = useState(true)

  const { notification } = useNotification();

  // 
  // ─── GET REWARDS ───────────────────────────────────────
  //
  useEffect(() => {
    const getRewards = async () => {
      const data = await Api({
        endpoint: '/rewards',
        method: 'GET'
      });

      if (data.success) setRewards(data.data)
      setLoading(false)
    }
    if (!rewards.length) getRewards()
  }, [rewards])

  // 
  // ─── HANDLE DELETE REWARD ───────────────────────────────────────
  //
  const handleDelete = async (e) => {
    e.preventDefault();

    const handleDelete = await Api({
      data: { reward_id: openAlert.reward_id },
      endpoint: '/rewards',
      method: 'DELETE'
    })

    if (handleDelete.success) {
      updateRewardsList({ reward_id: openAlert.reward_id, delete: true })
      return notification({ variant: 'success', message: handleDelete.message })
    } else {
      notification({ variant: 'error', message: handleDelete.message })
    }
  }

  // 
  // ─── UPDATE REWARDS LIST (ADD / UPDATE / DELETE) ───────────────────────────────────────
  //
  const updateRewardsList = (opt) => {
    setLoading(true)
    let isNewReward = rewards.filter(reward => reward.reward_id === opt.reward_id)
    if (!isNewReward.length) {
      setRewards([...rewards, opt])
    } else if (opt.delete) {
      let newRewardsArray = rewards.filter(reward => reward.reward_id !== opt.reward_id)
      setRewards([...newRewardsArray])
      setShow(false)
      setDisplayForm({})
    } else {
      let indexReward = rewards.findIndex(reward => reward.reward_id === opt.reward_id)
      rewards[indexReward] = { ...rewards[indexReward], ...opt }
      setRewards([...rewards])
    }
    setLoading(false)
  }

  const defaultReward = rewards.length ? rewards.find(reward => reward.reward_id === displayForm) : {}

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <AdminComponent>
    <S.Title>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M190.5 68.8L225.3 128l-1.3 0-72 0c-22.1 0-40-17.9-40-40s17.9-40 40-40l2.2 0c14.9 0 28.8 7.9 36.3 20.8zM64 88c0 14.4 3.5 28 9.6 40L32 128c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32l448 0c17.7 0 32-14.3 32-32l0-64c0-17.7-14.3-32-32-32l-41.6 0c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88l-2.2 0c-31.9 0-61.5 16.9-77.7 44.4L256 85.5l-24.1-41C215.7 16.9 186.1 0 154.2 0L152 0C103.4 0 64 39.4 64 88zm336 0c0 22.1-17.9 40-40 40l-72 0-1.3 0 34.8-59.2C329.1 55.9 342.9 48 357.8 48l2.2 0c22.1 0 40 17.9 40 40zM32 288l0 176c0 26.5 21.5 48 48 48l144 0 0-224L32 288zM288 512l144 0c26.5 0 48-21.5 48-48l0-176-192 0 0 224z" /></svg>
      Niveaux
      <Button onClick={() => { setShow(true); setDisplayForm({}) }}>{window.innerWidth > 800 && 'Ajouter'}<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" /></svg></Button>
    </S.Title>
    <Formfields field={{ type: "select", component: "select", name: "rewards", admin: true, placeholder: 'Choisir le niveau', data: rewards ? rewards.map((reward) => { return { id: reward.reward_id, label: reward.label, points: reward.points, reward: reward.reward } }) : [], width: '100%', adminValue: !Object.keys(displayForm).length, required: true, onChange: (e) => { setShow(true); setDisplayForm(e) } }} />
    <Loading loading={loading}>
      {show && <EditReward reward={defaultReward} label={rewards.length + 1} onDelete={(reward_id) => setOpenAlert({ open: true, reward_id: reward_id })} onUpdate={updateRewardsList} onClose={() => { setShow(false); setDisplayForm({}) }} />}
    </Loading>
    {openAlert.open && <AlertModal handleConfirm={(e) => { handleDelete(e); setOpenAlert({ ...openAlert, open: false }); setShow(false) }} onClose={() => setOpenAlert({ open: false })} />}
  </AdminComponent>
};

export default RewardsList;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}

S.Title = styled.div`
display: flex;
align-items: center;
gap: 10px;
color: ${({ theme }) => theme.textSecondary};;
font-size: 20px;
font-weight: 700;

& svg {
  fill: ${({ theme }) => theme.primary};
  width: 20px;
  height: 20px;
}

& button {
  margin-left: auto;
  width: fit-content;
  gap: 10px;
  ${breakpoints("padding", "", [
  { 800: "10px 15px" },
])};

  & svg {
    fill: ${({ theme }) => theme.secondary};
    width: 12px;
    height: 12px;
  }
}
`

S.Reward = styled(Reward)`
height: 44px;
padding: 0;
flex-direction: row;
padding-left: 50px;
max-width: ${({ theme }) => theme.width > 800 ? '15%' : '33%'};
`

S.Actions = styled.div`
display: flex;
gap: 5px;

& svg {
  width: 20px;
  height: 20px;
  fill: ${({ theme }) => theme.primary};
}
`